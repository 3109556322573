var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "", height: "30" } },
        _vm._l(_vm.items, function (item, index) {
          return _c("v-btn", {
            key: index,
            attrs: { variant: "outlined" },
            on: {
              click: function ($event) {
                return _vm.action(index)
              },
            },
          })
        }),
        1
      ),
      _c("h4", { attrs: { hidden: "" } }, [
        _vm._v(" " + _vm._s(_vm.show) + " "),
      ]),
      _c("DataTable", {
        ref: "table",
        attrs: {
          "default-options": {
            sortBy: ["lastModifyTime"],
            sortDesc: [true],
          },
          headers: _vm.headers,
          "load-data": _vm.loadData,
          "item-key": "id",
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.number`,
              fn: function ({ index }) {
                return [_vm._v(" " + _vm._s(index + 1) + " ")]
              },
            },
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mr-4",
                                        attrs: { color: "blue darken-3" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleConnect(item)
                                          },
                                        },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(" rowing ")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v("connect")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mr-4",
                                        attrs: { color: "blue darken-3" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEdit(item)
                                          },
                                        },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(" edit ")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v("information")])]
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c("I2CSchema", {
        ref: "i2cSchema",
        on: {
          addSuccess: _vm.handleAddSuccess,
          editSuccess: _vm.handleEditSuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }